import { types as sdkTypes } from './util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
const defaultLocations = [
  //Antwerp, Antwerp, Belgium:                        "bounds" => "51.377641,4.497881,51.143438,4.2227767",
  //Ghent, East Flanders, Belgium:            "bounds" => "51.188767,3.849331,50.979496,3.579786",
  //Bruges, West Flanders, Belgium  "bounds" => "51.3800786,3.310191,51.158071,3.134176",
  //Brussels, Brussels-Capital, Belgium            "bounds" => "50.913716,4.437046,50.796245,4.313869",
  //Mechelen, Antwerp, Belgium            "bounds" => "51.078595,4.549005,50.991164,4.370086",
  //Leuven, Flemish Brabant, Belgium            "bounds" => "50.944082,4.770378,50.8242,4.64063",
  //Hasselt, Limburg, Belgium            "bounds" => "50.98158,5.414913,50.867498,5.21193",
  //Ostend, West Flanders, Belgium            "bounds" => "50.98158,5.414913,50.867498,5.21193",
  {
    id: 'default-antwerp',
    predictionPlace: {
      address: 'Antwerp, Antwerp',
      bounds: new LatLngBounds(
        new LatLng(51.377641, 4.497881),
        new LatLng(51.143438, 4.2227767)
      ),
    },
  },
  {
    id: 'default-ghent',
    predictionPlace: {
      address: 'Ghent, East Flanders',
      bounds: new LatLngBounds(
        new LatLng(51.188767, 3.849331),
        new LatLng(50.979496, 3.579786)
      ),
    },
  },
  {
    id: 'default-bruges',
    predictionPlace: {
      address: 'Bruges, West Flanders',
      bounds: new LatLngBounds(
        new LatLng(51.3800786 ,3.310191),
        new LatLng(51.158071, 3.134176)
      ),
    },
  },
  {
    id: 'default-brussels',
    predictionPlace: {
      address: 'Brussels, Brussels-Capital',
      bounds: new LatLngBounds(
        new LatLng(50.913716, 4.437046),
        new LatLng(50.796245, 4.313869)
      ),
    },
  },
  {
    id: 'default-mechelen',
    predictionPlace: {
      address: 'Mechelen, Antwerp',
      bounds: new LatLngBounds(
        new LatLng(51.078595, 4.549005),
        new LatLng(50.991164, 4.370086)
      ),
    },
  },
  {
    id: 'default-leuven',
    predictionPlace: {
      address: 'Leuven, Flemish Brabant',
      bounds: new LatLngBounds(
        new LatLng(50.944082,4.770378),
        new LatLng(50.8242,4.64063)
      ),
    },
  },
  {
    id: 'default-hasselt',
    predictionPlace: {
      address: 'Hasselt, Limburg',
      bounds: new LatLngBounds(
        new LatLng(50.98158,5.414913),
        new LatLng(50.867498,5.21193)
      ),
    },
  },
  {
    id: 'default-ostend',
    predictionPlace: {
      address: 'Ostend, West Flanders',
      bounds: new LatLngBounds(
        new LatLng(51.2605752,3.004327),
        new LatLng(51.184557,2.8334245)
      ),
    },
  },
];
export default defaultLocations;
