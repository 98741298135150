import React, { useState } from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { findOptionsForCategorySelectFilter, findOptionsForSelectFilter } from '../../util/search';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { LayoutWrapperMain, ListingLink } from '../../components';

import config from '../../config';

import css from './EditListingCategoryPanel.module.css';
import EditListingCategoryForm from '../../forms/EditListingCategoryForm/EditListingCategoryForm';
import { TABS } from '../EditListingWizard/EditListingWizard';
import FieldRadioButton from '../FieldRadioButton/FieldRadioButton';
import { EditListingFeaturesForm } from '../../forms';
import { LISTING_PAGE_PARAM_TYPE_DRAFT } from '../../util/urlHelpers';


const findCategoryFilter = (filters) => {
  return filters.find(f => f.id == "categories");
}
const EditListingCategoryPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    intl,
    category,
  } = props;

  // const currentPathParams = {
  //   ...params,
  //   type: LISTING_PAGE_PARAM_TYPE_DRAFT,
  //   id: listingId,
  // };

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { description, title, publicData } = currentListing.attributes;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingCategoryPanel.title"
      values={{
        listingTitle: (
          <ListingLink listing={listing}>
            <FormattedMessage id="EditListingCategoryPanel.listingTitle" values={{ category }}/>
          </ListingLink>
        ),
      }}
    />
  ) : (
    <FormattedMessage
      id="EditListingCategoryPanel.createListingTitle" values={{ category }}/>
  );
  const hasCategory = publicData && publicData.category;




  const categoryFilter = findCategoryFilter(config.custom.filters);
  const categoryOptions = categoryFilter.config.options;

  const initialValues = {}
  const categoryFilterConfig = config.custom.filters.find(f => f.id == 'categories');

  return (
    <div className={css.content}>
      <div className={css.headingContainer}>
        <h1 className={css.title}>
          <FormattedMessage id="EditListingCategoryPanel.heading" />
        </h1>
      </div>
      <EditListingCategoryForm
        className={css.form}
        name="category"
        initialValues={initialValues}
        category={category}
        onSubmit={values => {
          const updatedCategory = {
            category: values.category,
          };
          onSubmit({publicData: updatedCategory});
        }}
        onChange={onChange}
        saveActionMsg={submitButtonText}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        categoryFilterConfig={categoryFilterConfig}
        intl={intl}
      />
    </div>
  );
};

EditListingCategoryPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingCategoryPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingCategoryPanel;
